<template>
  <div>
    <div v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('EXPENDITURE_TYPE')})  | capitalize }}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">
          <!-- name text input -->
          <b-form-group
              id="name-form-group"
              :label="$t('EXPENDITURE_TYPE_NAME_LABEL') | capitalize"
              label-for="name-input"
              :state="state('name')">
            <b-form-input class="w-100 ml-1" id="name-input" type="text"
                          v-model="$v.form.name.$model"
                          :state="state('name')"
                          trim lazy></b-form-input>

            <b-form-invalid-feedback id="name-input-feedback">
              <div v-for="error in errors('name')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('ACCOUNT_NAME_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- name text input -->

          <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
            <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import {Form, ProcessWithLoadingAndMessage} from "@/mixins";
import ExpenditureTypes from "@/expenditureType";
import Constants from "@/constants";

export default {
  name: "ExpenditureTypeForm",
  mixins: [Form, ProcessWithLoadingAndMessage],
  methods: {
    async doSave(id, data) {
      return id ? await ExpenditureTypes.update(id, data) : await ExpenditureTypes.create(data);
    },
    getData() {
      this.loadingFormData = true;
      ExpenditureTypes.findById(this.id)
          .then(resp => {
            this.form.name = resp.data.name;
            this.originals.name = resp.data.name
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });
    }
  },
  data() {
    return {
      loadingFormData: false,
      originals: {
        name: null
      },
      form: {
        name: null
      },
      editPage: 'EXPENDITURE_TYPE_EDIT_TITLE'
    }
  },
  validations: {
    form: {
      name: {
        minLength: minLength(4),
        maxLength: maxLength(200),
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (!value || value === '') return true

          if (this.originals.name && this.originals.name === value) return true;

          // simulate async call, fail for all logins with even length
          return ExpenditureTypes.nameExists(value);
        }, required
      }
    }
  }
}
</script>

<style scoped>

</style>
